import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import fubService from "../services/fubService";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import { dispatch } from "d3";
import { clearCountData } from "../redux/slices/userSlice";
import { useDispatch } from "react-redux";

const AuthContext = createContext();

// process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log(process.env.REACT_APP_API_BASE_URL, "REACT_APP_API_BASE_URL");

  console.log(API_BASE_URL);
  const login = useCallback(
    async (email, password, rememberMe) => {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
          email,
          password,
          rememberMe,
        });
        const { user, token, selections } = response.data;

        // Existing logic remains untouched
        setUser(user);
        Cookies.set("token", token, { expires: 7, path: "/" });
        localStorage.setItem("token", token);
        localStorage.setItem("rememberMe", rememberMe);
        localStorage.setItem("user", JSON.stringify(user));
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

        if (user.fubApiKey) {
          fubService.setApiKey(user.fubApiKey);
        }

        // Check subscription and redirect accordingly
        if (user.subscriptionStatus !== "active") {
          navigate("/register-payment");
        } else {
          if (!user.fubApiKey) {
            // Show FUB Key integration popup
            navigate("/settings");
          } else {
            // Redirect logic based on selections
            const {
              agentIds = [],
              smartListIds = [],
              stageIds = [],
            } = selections || {};

            if (agentIds.length && smartListIds.length && stageIds.length) {
              navigate("/smart-lists");
            } else if (
              agentIds.length &&
              stageIds.length &&
              !smartListIds.length
            ) {
              navigate("/stage-counts");
            } else {
              navigate("/");
            }
          }
        }
      } catch (error) {
        console.error("Login error:", error.response?.data || error.message);
        throw error.response?.data || error;
      }
    },
    [navigate]
  );

  useEffect(() => {
    const restoreUser = async () => {
      try {
        const storedUser = localStorage.getItem("user");
        const token = Cookies.get("token");
        if (storedUser && token) {
          setUser(JSON.parse(storedUser));
          console.log(user);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          console.log("User restored from storage.");
        }
      } catch (error) {
        console.error("Error restoring user data:", error);
        setUser(null); // Clear user state on failure
        Cookies.remove("token");
        localStorage.removeItem("user");
      } finally {
        setLoading(false); // Always ensure loading is set to false
      }
    };

    restoreUser();
  }, []);

  const register = useCallback(async (email, password, promoCode) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/register`, {
        email,
        password,
        promoCode,
      });

      if (response.data.sessionId) {
        // If sessionId is returned, redirect to Stripe checkout
        return response.data;
      } else if (response.data.message) {
        // If there's a success message (valid promo code)
        return response.data;
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "Failed to register. Please try again.";
      throw new Error(errorMsg);
    }
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    // dispatch(clearCountData());
    localStorage.removeItem("token");
    Cookies.remove("token");
    localStorage.removeItem("user");
    localStorage.removeItem("rememberMe");
    delete axios.defaults.headers.common["Authorization"];
    fubService.setApiKey(null);
    navigate("/login");
  }, [navigate]);

  const updateUser = useCallback((updatedUser) => {
    setUser(updatedUser);
    if (updatedUser.fubApiKey) {
      fubService.setApiKey(updatedUser.fubApiKey);
    }
  }, []);

  const refreshUser = useCallback(async () => {
    if (isRedirecting) return;

    try {
      console.log("refreshing user");
      const response = await axios.get(`${API_BASE_URL}/api/auth/user`);
      console.log("User data fetched:", response.data);
      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      // const userData = localStorage.getItem("user");
      // setUser(userData);
      if (response.data.fubApiKey) {
        fubService.setApiKey(response.data.fubApiKey);
      }
      if (response.data.subscriptionStatus !== "active") {
        setIsRedirecting(true);
        navigate("/register-payment");
      }
      console.log("Refreshing user data");
      console.log(user);
    } catch (error) {
      console.error("Error refreshing user data:", error);
      if (error.response && error.response.status === 403) {
        setIsRedirecting(true);
        navigate("/register-payment");
      } else {
        logout();
      }
    } finally {
      setIsRedirecting(false);
    }
  }, [navigate, logout, isRedirecting]);

  // update updateFubApiKey

  const updateFubApiKey = useCallback(
    async (apiKey) => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/auth/update-fub-api-key`,
          { fubApiKey: apiKey }
        );

        let successMessage = response.data.message;

        if (response.data.user) {
          updateUser(response.data.user); // Ensure response has the `user` object
          return { success: true, successMessage: successMessage };
        } else {
          console.error("User object missing in response");
          return { success: false, error: "User object missing in response" };
        }
      } catch (error) {
        console.error(
          "Error updating FUB API key:",
          error.response?.data || error.message
        );

        const errorMessage =
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : "An unexpected error occurred.";

        return { success: false, error: errorMessage };
      }
    },
    [updateUser]
  );

  const checkSubscription = useCallback(() => {
    if (user && user.subscriptionStatus !== "active" && !isRedirecting) {
      setIsRedirecting(true);
      navigate("/register-payment");
    }
  }, [user, navigate, isRedirecting]);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const token = localStorage.getItem("token");
      const rememberMe = localStorage.getItem("rememberMe") === "true";
      if (token && rememberMe) {
        try {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          await refreshUser();
        } catch (error) {
          console.error("Error verifying token:", error);
          localStorage.removeItem("token");
          localStorage.removeItem("rememberMe");
          delete axios.defaults.headers.common["Authorization"];
          navigate("/login");
        }
      }
      setLoading(false);
    };

    checkLoggedIn();
  }, [navigate, refreshUser]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        const newToken = response.headers["x-new-token"];
        if (newToken) {
          localStorage.setItem("token", newToken);
          axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        }
        return response;
      },
      (error) => {
        console.log(error.message);
        if (error.response && error.response.status === 403 && !isRedirecting) {
          // setIsRedirecting(true);
          navigate("/settings");
        }
        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate, isRedirecting]);

  const contextValue = {
    user,
    loading,
    login,
    register,
    logout,
    updateUser,
    updateFubApiKey,
    checkSubscription,
    refreshUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
