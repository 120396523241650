import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import DialogueBox from "./DialogueBox";

const API_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;
console.log("API_URL:", API_URL);
const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
);

const SubscriptionManagement = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
      console.log(user, "user hg");
      // return;
    }
  }, [user]);

  const fetchSubscription = async () => {
    try {
      console.log("Fetching subscription...");
      const response = await axios.get(`${API_URL}/api/billing/subscription`);
      console.log("Subscription response:", response.data);
      setSubscription(response.data);
    } catch (error) {
      console.error("Error fetching subscription:", error.response || error);
      if (error.response && error.response.status === 404) {
        console.log("No active subscription found. User can create a new one.");
        setSubscription(null);
      } else {
        setError(
          `Failed to fetch subscription information. Error: ${
            error.response?.data?.error || error.message
          }`
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshUser();
    console.log(user, "user");
  }, []);

  useEffect(() => {
    fetchSubscription();
  }, []);

  const handleSubscribe = async () => {
    setError(""); // Reset error message
    try {
      console.log("Attempting to create checkout session...");
      const response = await axios.post(
        `${API_URL}/api/billing/create-checkout-session`,
        { promoCode }
      );

      console.log("Checkout session created:", response.data);

      if (response.data.sessionId) {
        // If sessionId is returned, redirect to Stripe checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });

        if (error) {
          console.error("Stripe redirect error:", error.message);
          setError(error.message);
        }
      } else {
        // If subscriptionCreated is true, handle success scenario (though it seems unnecessary with current backend logic)
        console.log("Subscription was successfully created");
        await refreshUser();
        console.log("User details after refresh:");
        console.log(user); // Ensure this is logging the updated user
        navigate("/settings");
        fetchSubscription();
      }
    } catch (error) {
      console.error("Subscription error:", error.response || error.message);
      setError(
        `Failed to create subscription. ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleCancelSubscription = async () => {
    setError("");
    try {
      await axios.post(`${API_URL}/api/billing/cancel-subscription`);
      await refreshUser();
      fetchSubscription();
    } catch (error) {
      console.error("Cancel subscription error:", error.response || error);
      setError(
        `Failed to cancel subscription. Error: ${
          error.response?.data?.error || error.message
        }`
      );
    }
  };

  const handleReactivateSubscription = async () => {
    setError("");
    try {
      await axios.post(`${API_URL}/api/billing/reactivate-subscription`);
      await refreshUser();
      fetchSubscription();
    } catch (error) {
      console.error("Reactivate subscription error:", error.response || error);
      setError(
        `Failed to reactivate subscription. Error: ${
          error.response?.data?.error || error.message
        }`
      );
    }
  };

  const handleApplyPromo = async () => {
    setError("");
    try {
      const response = await axios.post(`${API_URL}/api/billing/apply-promo`, {
        promoCode,
      });
      if (response.data.valid) {
        setError(
          'Promo code applied successfully. Click "Subscribe Now" to use it.'
        );
      } else {
        setError("Invalid promo code. Please try again.");
      }
    } catch (error) {
      console.error("Apply promo error:", error.response || error);
      setError(
        `Failed to apply promo code. Error: ${
          error.response?.data?.error || error.message
        }`
      );
    }
  };

  // Prefetch subscription data on component mount
  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await axios.post(
          `${API_URL}/api/billing/check-subscription`
        );
        console.log("Subscription data prefetched:", response.data);
        setSubscription(response.data);
      } catch (error) {
        console.error("Error prefetching subscription:", error);
        setSubscription({
          status: "inactive",
          subscriptionId: null,
          currentPeriodEnd: null,
          cancelAtPeriodEnd: null,
        }); // Fallback if there's an error
      }
    };

    if (user) {
      fetchSubscription();
    }
  }, [user]);

  return (
    <>
      {showFUBKEYDialog ? (
        <DialogueBox />
      ) : (
        <Box sx={{ mt: 3, p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {subscription && subscription.status === "active"
              ? "Manage Your Subscription"
              : "Subscribe to Our Service"}
          </Typography>

          {subscription && subscription.status === "active" ? (
            <Box>
              <Typography>Status: {subscription.status}</Typography>
              {subscription.currentPeriodEnd && (
                <Typography>
                  Current period ends:{" "}
                  {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                </Typography>
              )}
              {subscription.cancelAtPeriodEnd ? (
                <Button
                  onClick={handleReactivateSubscription}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Reactivate Subscription
                </Button>
              ) : (
                <Button
                  onClick={handleCancelSubscription}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  Cancel Subscription
                </Button>
              )}
            </Box>
          ) : (
            <Box>
              <Typography paragraph>
                Please set up your subscription to continue using our services.
              </Typography>
              <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  label="Promo Code (Optional)"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <Button
                  onClick={handleSubscribe}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  Subscribe Now
                </Button>
              </Box>
            </Box>
          )}

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              Message: {error}
            </Typography>
          )}

          {/* <Button
            onClick={checkSubscription}
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
          >
            Check Subscription Status
          </Button> */}
          <div>
            {/* Button to show subscription details */}
            <Button
              onClick={() => setDialogOpen(true)}
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
            >
              Check Subscription Status
            </Button>

            {/* Dialog for Subscription Details */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Subscription Details</DialogTitle>
              <DialogContent>
                {subscription ? (
                  <div>
                    <Typography>
                      <strong>Status:</strong> {subscription.status}
                    </Typography>
                    <Typography>
                      <strong>Subscription ID:</strong>{" "}
                      {subscription.subscriptionId || "N/A"}
                    </Typography>
                    <Typography>
                      <strong>Current Period End:</strong>{" "}
                      {subscription.currentPeriodEnd || "N/A"}
                    </Typography>
                    <Typography>
                      <strong>Cancel At Period End:</strong>{" "}
                      {subscription.cancelAtPeriodEnd ? "Yes" : "No"}
                    </Typography>
                  </div>
                ) : (
                  <Typography>No subscription data available.</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>
      )}
    </>
  );
};

export default SubscriptionManagement;
