import React from "react";
import { Typography, Card, CardContent, Grid, Tooltip } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

const AgentScorecard = ({ agent }) => {
  if (!agent) return null;
  console.log(agent.avgDailyTotal);
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">{agent.name}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Tooltip title="Performance Score: Calculated based on average daily and stage totals. Higher is better.">
              <Typography variant="subtitle2">
                Performance Score <HelpOutline fontSize="small" />
              </Typography>
            </Tooltip>
            <Typography
              variant="h4"
              color={
                agent.performanceScore > 50 ? "success.main" : "warning.main"
              }
            >
              {agent.performanceScore.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="Average Daily Total: The average number of leads in the agent's daily smart lists. Lower is better.">
              <Typography variant="subtitle2">
                Avg Smart List Total <HelpOutline fontSize="small" />
              </Typography>
            </Tooltip>
            <Typography variant="h4">{agent.avgDailyTotal}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="Average Stage Total: The average number of leads in the agent's stage pipeline. Lower is better for early stages, higher for later stages.">
              <Typography variant="subtitle2">
                Avg Stages Total <HelpOutline fontSize="small" />
              </Typography>
            </Tooltip>
            <Typography variant="h4">{agent.avgStageTotal}</Typography>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Trends:
        </Typography>
        <Tooltip title="Daily Trend: Indicates how the agent's daily total is changing over time.">
          <Typography>
            Smart List Trend: {agent.dailyTrend} <HelpOutline fontSize="small" />
          </Typography>
        </Tooltip>
        {/* {stageLogs.length > 0 && agent.avgStageTotal && ( */}
        <Tooltip title="Stage Trend: Indicates how the agent's stage total is changing over time.">
          <Typography>
            Stage Trend: {agent.stageTrend} <HelpOutline fontSize="small" />
          </Typography>
        </Tooltip>
        {/* )} */}
      </CardContent>
    </Card>
  );
};

export default AgentScorecard;
