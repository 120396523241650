import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import Cookies from "js-cookie";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  Autocomplete,
  Card,
  Chip,
  List,
  ListItem,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { useMediaQuery, styled, useTheme } from "@mui/material";
import { useAgentColors } from "../contexts/AgentColorContext";
import { clearCountData, setCountData } from "../redux/slices/userSlice";
import { count } from "d3";
import { useAuth } from "../contexts/AuthContext";
import DialogueBox from "./DialogueBox";
import ShowStepper from "./ShowStepper";

const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles((theme) => ({
  button: {
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  card: {
    transition: "box-shadow 0.3s",
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  padding: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
}));

// const CustomTooltip = styled(Tooltip)({
//   [`& .MuiTooltip-tooltip`]: {
//     fontSize: "16px", // Adjust the font size as needed
//     maxWidth: "405px",
//   },
// });

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "16px", // Adjust the font size as needed
    maxWidth: "450px", // Set max width to 450px
  },
});

const SelectionComponent = () => {
  const [agents, setAgents] = useState([]);
  const [savedStages, setSavedStages] = useState([]);
  const [smartLists, setSmartLists] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedSmartLists, setSelectedSmartLists] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [counts, setCounts] = useState(null);
  const [fetchDate, setFetchDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showCounts, setShowCounts] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { agentColors, fetchAgentColors } = useAgentColors();
  const { user } = useAuth();
  const userId = user?._id;

  // Load counts data from localStorage on component mount or when countData changes
  useEffect(() => {
    if (userId) {
      // Retrieve counts data from localStorage
      const persistedCounts = localStorage.getItem(`counts-${userId}`);
      const persistedStages = localStorage.getItem(`stages-${userId}`);
      const countData = JSON.parse(persistedCounts);
      const stagesData = JSON.parse(persistedStages);

      // Count date
      const countDate = countData?.date;
      if (countData && countDate && stagesData) {
        const parsedDate = parseISO(countDate);
        const formattedDate = isNaN(parsedDate)
          ? "Invalid Date"
          : format(parsedDate, "MM/dd/yyyy");
        setFetchDate(formattedDate);

        setCounts(countData);
        setSavedStages(stagesData);
      }
    }
  }, [userId]);

  const fetchAgents = useCallback(async () => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedAgents = Object.entries(response.data).sort(([, a], [, b]) =>
        a.localeCompare(b)
      );
      return sortedAgents.map(([id, name]) => ({ value: id, label: name }));
    } catch (error) {
      setErrorMessage("Error fetching agents. Please try again.");
      return [];
    }
  }, []);

  const fetchSmartLists = useCallback(async () => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/smartlists`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedSmartLists = Object.entries(response.data).sort(
        ([, a], [, b]) => a.localeCompare(b)
      );
      return sortedSmartLists.map(([id, name]) => ({ value: id, label: name }));
    } catch (error) {
      console.error("Error fetching smart lists:", error);
      setErrorMessage("Error fetching smart lists. Please try again.");
      return [];
    }
  }, []);

  const fetchStages = useCallback(async () => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/stages`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedStages = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return sortedStages.map((stage) => ({
        value: stage.id,
        label: stage.name,
      }));
    } catch (error) {
      console.error("Error fetching stages:", error);
      setErrorMessage("Error fetching stages. Please try again.");
      return [];
    }
  }, []);

  const fetchSelections = useCallback(
    async (agentOptions, smartListOptions, stageOptions) => {
      try {
        // const token = localStorage.getItem("token");
        const token = Cookies.get("token");
        const response = await axios.get(`${API_BASE_URL}/api/get-selections`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const selections = response.data || {};

        const selectedAgentOptions = (selections.agentIds || [])
          .map((id) => agentOptions.find((agent) => agent.value === id))
          .filter(Boolean);
        const selectedSmartListOptions = (selections.smartListIds || [])
          .map((id) =>
            smartListOptions.find((smartList) => smartList.value === id)
          )
          .filter(Boolean);
        const selectedStageOptions = (selections.stageIds || [])
          .map((id) =>
            stageOptions.find(
              (stage) => stage.value.toString() === id.toString()
            )
          )
          .filter(Boolean);

        setSelectedAgents(selectedAgentOptions);
        setSelectedSmartLists(selectedSmartListOptions);
        setSelectedStages(selectedStageOptions);
      } catch (error) {
        console.error("Error fetching selections:", error);
        setErrorMessage("Error fetching selections. Please try again.");
      }
    },
    []
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [agentOptions, smartListOptions, stageOptions] = await Promise.all([
        fetchAgents(),
        fetchSmartLists(),
        fetchStages(),
      ]);

      setAgents(agentOptions);
      setSmartLists(smartListOptions);
      setStages(stageOptions);

      await fetchSelections(agentOptions, smartListOptions, stageOptions);
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [fetchAgents, fetchSmartLists, fetchStages, fetchSelections]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchAgentColors();
  }, [fetchAgentColors]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelection = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/selected-counts`,
        {
          agentIds: selectedAgents.map((agent) => agent.value),
          smartListIds: selectedSmartLists.map((smartList) => smartList.value),
          stageIds: selectedStages.map((stage) => stage.value),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const responseData = response.data;

      if (userId) {
        localStorage.setItem(`counts-${userId}`, JSON.stringify(responseData));
      }

      // Update counts state with the fetched data
      setCounts(responseData);
      if (responseData.date) {
        const parsedDate = parseISO(responseData.date);
        const formattedDate = isNaN(parsedDate)
          ? "Invalid Date"
          : format(parsedDate, "dd/MM/yyyy");
        setFetchDate(formattedDate);
        if (userId) {
          localStorage.setItem(`fetchDate-${userId}`, formattedDate); // Save formatted date to localStorage with user-specific key
        }
        // dispatch(
        //   setCountData({
        //     ...responseData,
        //     formattedDate,
        //   })
        // );
      } else {
        setFetchDate("Invalid Date");
      }
    } catch (error) {
      console.error("Error fetching selected counts:", error);
      setErrorMessage("Error fetching selected counts. Please try again.");
      setFetchDate("Invalid Date");
    }
    //  finally {
    //   setIsGettingCounts(false);
    // }
  };

  const assignColorToAgent = async (agentName) => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      await axios.post(
        `${API_BASE_URL}/api/agent-colors/save-agent`,
        { agentName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error(`Error assigning color to agent ${agentName}:`, error);
    }
  };

  // ==============================
  const handleSaveSelections = async () => {
    dispatch(clearCountData());
    if (userId) {
      // Remove item from localStorage
      localStorage.removeItem(`stages-${userId}`);
      localStorage.removeItem(`fetchDate-${userId}`);
    }
    setIsSaving(true);
    // Set saving state to true
    setIsSaved(true);

    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const dataToSave = {
        agentIds: selectedAgents.map((agent) => agent.value),
        smartListIds: selectedSmartLists.map((smartList) => smartList.value),
        stageIds: selectedStages.map((stage) => stage.value),
      };
      const stages = dataToSave.stageIds;
      localStorage.setItem(`stages-${userId}`, JSON.stringify(stages));
      setSavedStages(stages);

      await axios.post(`${API_BASE_URL}/api/save-selections`, dataToSave, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const colorAssignmentPromises = selectedAgents.map((agent) =>
        assignColorToAgent(agent.label)
      );
      await Promise.all(colorAssignmentPromises);

      await fetchAgentColors();

      await handleSelection();

      setShowCounts(true);
      setIsSaved(false);
      setSuccessMessage("Selections saved successfully!");
    } catch (error) {
      console.error("Error saving selections:", error);
      setErrorMessage("Error saving selections. Please try again.");
    } finally {
      setIsSaving(false); // Set saving state to false
    }
  };

  const handleClearSelections = async () => {
    // dispatch(clearCountData());
    if (userId) {
      localStorage.removeItem(`counts-${userId}`); // Remove counts data using user-specific key
      localStorage.removeItem(`fetchDate-${userId}`);
      localStorage.removeItem(`stages-${userId}`);
    }

    setCounts("");
    setFetchDate("");
    setSelectedAgents([]);
    setSelectedSmartLists([]);
    setSelectedStages([]);
    setSavedStages([]);
    // setAgents([]);
    // setSmartLists([]);
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      await axios.post(
        `${API_BASE_URL}/api/clear-selections`, // An endpoint to clear selections

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setOpen(false);
      setSuccessMessage("Configurations have been reset successfully!");
    } catch (error) {
      console.error("Error fetching stages:", error);

      setErrorMessage("Error clearing selections. Please try again.");
    }
  };

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
    }
  }, [user]);

  const DraggableListItem = ({ task, index }) => {
    return (
      <Draggable key={task.id} draggableId={task.id} index={index}>
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {task.content}
          </ListItem>
        )}
      </Draggable>
    );
  };

  const DroppableList = ({ tasks }) => {
    return (
      <Droppable droppableId="droppable">
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {tasks.map((task, index) => (
              <DraggableListItem key={task.id} task={task} index={index} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    );
  };

  if (loading || !dataFetched) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (showFUBKEYDialog) {
    return <DialogueBox />;
  }

  return (
    <>
      {/* {showFUBKEYDialog ? (
        <DialogueBox />
      ) : ( */}
      <StyledContainer>
        <Box p={4}>
          {/* <DragDropContext onDragEnd={handleDragEnd}>
              <DroppableList tasks={tasks} />
            </DragDropContext> */}
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography variant="h4" component="h1" gutterBottom>
                  {/* Select Agents, Smart Lists, and Stages  */}
                  Set Your Metrics Configuration
                </Typography>
                <CustomTooltip
                  title={
                    <span style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                      Set the metrics configurations to track the agent's
                      performance
                    </span>
                  }
                  arrow
                >
                  <IconButton
                    size="large"
                    style={{ marginLeft: "8px", marginTop: "-10px" }}
                  >
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
              </Box>
            </Grid>
            {/* loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                  position="absolute"
                  top="50%" 
                  left="50%"
                  sx={{
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : ( */}
            <>
              {/* {agents.length > 0 && ( */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Agents
                </Typography>
                <StyledAutocomplete
                  multiple
                  options={agents}
                  value={selectedAgents}
                  onChange={(event, value) => setSelectedAgents(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select agents"
                      variant="outlined"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
              {/* )} */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Smart Lists
                </Typography>
                <StyledAutocomplete
                  disabled={selectedAgents.length === 0}
                  multiple
                  options={smartLists}
                  value={selectedSmartLists}
                  onChange={(event, value) => setSelectedSmartLists(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select smart lists"
                      variant="outlined"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Stages
                </Typography>
                <StyledAutocomplete
                  disabled={selectedAgents.length === 0}
                  multiple
                  options={stages}
                  value={selectedStages}
                  onChange={(event, value) => setSelectedStages(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select stages"
                      variant="outlined"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={2}
                  mb={4}
                >
                  <StyledButton
                    onClick={handleSaveSelections}
                    variant="contained"
                    color="secondary"
                    disabled={
                      isSaving ||
                      selectedAgents.length === 0 ||
                      selectedSmartLists.length === 0
                      // ||selectedStages.length === 0
                    } // Disable button while saving
                  >
                    {isSaving ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      // "Save Selections"
                      // "Save configuration"
                      "Save  Metrics Configuration"
                    )}
                  </StyledButton>
                  <div>
                    {/* Other component JSX */}

                    {/* Clear Selections Button */}
                    {/* {(!selectedAgents.length > 0 ||
                      !selectedSmartLists.length > 0 ||
                      !selectedStages.length > 0) && ( */}
                    <StyledButton
                      // onClick={handleClearSelections}
                      onClick={handleClickOpen}
                      variant="outlined"
                      color="primary"
                      // disabled
                      disabled={
                        selectedAgents.length === 0 &&
                        selectedSmartLists.length === 0 &&
                        selectedStages.length === 0
                      }
                    >
                      {/* Clear Selections */}
                      Reset
                    </StyledButton>

                    <Dialog
                      // fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      maxWidth="xl"
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        {/* Are you sure to reset the configurations? */}
                        Are you sure you want to reset the configuration?
                        {/* {"Use Google's location service?"} */}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText></DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          autoFocus
                          variant="outlined"
                          color="error"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          onClick={handleClearSelections}
                          variant="outlined"
                          color="success"
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {/* )} */}

                    {/* Other component JSX */}
                  </div>
                </Box>
              </Grid>
              {/* {counts.length} */}
              {counts && Object.keys(counts).length > 0 && (
                <Grid item xs={12}>
                  <Box mt={4}>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {/* Counts - Data pulled on {fetchDate} */}
                      Selected Metrics Report {fetchDate}
                      {/* Selected Configuration Report {counts.date} */}
                      {/* counts */}
                    </Typography>
                    <Box mb={2}>
                      {
                        // counts.stageCounts && counts.stageCounts.counts && (
                        counts?.stageCounts?.counts &&
                          Object.values(counts.stageCounts.counts).some(
                            (stages) => Object.keys(stages).length > 0
                          ) && (
                            <>
                              <Typography variant="subtitle1" gutterBottom>
                                Stage Counts
                              </Typography>
                              {counts?.stageCounts?.counts &&
                                counts?.stageCounts?.agentMap &&
                                counts?.stageCounts?.stageMap &&
                                Object.entries(counts?.stageCounts?.counts).map(
                                  ([agentId, stages]) => (
                                    <Card
                                      key={agentId}
                                      variant="outlined"
                                      className={classes.card}
                                      style={{
                                        padding: "16px",
                                        marginBottom: "16px",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Agent:{" "}
                                        {counts?.stageCounts?.agentMap[agentId]}
                                      </Typography>
                                      {Object.entries(stages || {}).map(
                                        ([stageId, count]) => (
                                          <Chip
                                            key={stageId}
                                            label={`${counts?.stageCounts?.stageMap[stageId]}: ${count}`}
                                            style={{
                                              marginRight: "8px",
                                              marginBottom: "8px",
                                            }}
                                          />
                                        )
                                      )}
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Total Leads:{" "}
                                        {Object.values(stages || {}).reduce(
                                          (a, b) => a + b,
                                          0
                                        )}
                                      </Typography>
                                    </Card>
                                  )
                                )}
                            </>
                          )
                      }
                    </Box>
                    <Box>
                      {counts.smartListCounts &&
                        counts.smartListCounts.counts && (
                          // &&
                          // Object.values(counts.smartListCounts.counts).some(
                          //   (stages) => Object.keys(stages).length > 0
                          // )
                          <>
                            <Typography variant="subtitle1" gutterBottom>
                              Smart List Counts
                            </Typography>
                            {counts?.smartListCounts?.counts &&
                              counts?.smartListCounts?.agentMap &&
                              counts?.smartListCounts?.smartListMap &&
                              Object.entries(
                                counts?.smartListCounts?.counts
                              ).map(([agentId, smartLists]) => (
                                <Card
                                  key={agentId}
                                  variant="outlined"
                                  className={classes.card}
                                  style={{
                                    padding: "16px",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography variant="subtitle2" gutterBottom>
                                    Agent:{" "}
                                    {counts?.smartListCounts?.agentMap[agentId]}
                                  </Typography>
                                  {Object.entries(smartLists || {}).map(
                                    ([smartListId, count]) => (
                                      <Chip
                                        key={smartListId}
                                        label={`${counts?.smartListCounts?.smartListMap[smartListId]}: ${count}`}
                                        style={{
                                          marginRight: "8px",
                                          marginBottom: "8px",
                                        }}
                                      />
                                    )
                                  )}
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    Total Leads:{" "}
                                    {Object.values(smartLists || {}).reduce(
                                      (a, b) => a + b,
                                      0
                                    )}
                                  </Typography>
                                </Card>
                              ))}
                          </>
                        )}
                    </Box>
                  </Box>
                </Grid>
              )}
            </>
            {/* )} */}
          </Grid>
        </Box>
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
        >
          <Alert onClose={() => setErrorMessage("")} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage("")}
        >
          <Alert onClose={() => setSuccessMessage("")} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </StyledContainer>
      {/* )} */}
    </>
  );
};

export default SelectionComponent;
