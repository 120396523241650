import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import ShowStepper from "../ShowStepper";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SingleLeadClaimEntry = ({ event, claimedAtFormatted, uniqueKey }) => {
  const [selectedLead, setSelectedLead] = useState(null);
  const [open, setOpen] = useState(false);

  const handleViewDetailsClick = async (leadId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/${leadId}`
      );
      console.log("API response:", response.data);
      setSelectedLead(response.data); // Store selected lead's data
      setOpen(true); // Open the dialog
    } catch (error) {
      console.error("Error fetching claim events:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    fontSize: "1.1rem",
    minWidth: "180px",
    textTransform: "capitalize",
  }));
  return (
    <TableRow key={uniqueKey}>
      {/* Lead Name */}
      <TableCell>{event.leadName || "Unknown"}</TableCell>

      {/* Claim Type */}
      <TableCell>
        {(() => {
          switch (event.claimTypeDetails?.type) {
            case "ReleasedToPond":
              return "Released To Pond";
            case "FTC":
              return "First To Claim";
            case "Reassigned":
              return "Reassigned";
            case "ClaimedFromPond":
              return "Pond Claim";
            case "ClaimedFromGroup":
              return "Claimed From Group";
            default:
              return "No Change";
          }
        })()}
      </TableCell>

      {/* Agent From */}
      <TableCell>
        {event.claimTypeDetails?.type === "Reassigned" &&
        event.claimTypeDetails?.previousAssignment?.assignmentType === "agent"
          ? event.claimTypeDetails.previousAssignment.assignedToName
          : event.claimTypeDetails?.type === "ReleasedToPond" &&
            event.claimTypeDetails?.previousAssignment?.assignmentType ===
              "agent"
          ? event.claimTypeDetails.previousAssignment.assignedToName
          : "N/A"}
      </TableCell>

      {/* Agent To */}
      <TableCell>
        {event.claimTypeDetails?.type === "ClaimedFromPond" &&
        event.claimTypeDetails?.newAssignment?.assignmentType === "agent"
          ? event.claimTypeDetails.newAssignment?.assignedToName
          : event.claimTypeDetails?.type === "Reassigned" &&
            event.claimTypeDetails?.newAssignment?.assignmentType === "agent"
          ? event.claimTypeDetails.newAssignment?.assignedToName
          : event.claimTypeDetails?.type === "FTC"
          ? event.claimTypeDetails.claimedByName // For FTC type, show claimedByName
          : "N/A"}
      </TableCell>

      {/* Pond (Current assignment type) */}
      <TableCell>
        {event.claimTypeDetails?.type === "ClaimedFromPond" &&
        event.claimTypeDetails?.previousAssignment?.assignmentType === "pond"
          ? event.claimTypeDetails.previousAssignment.pondName
          : event.claimTypeDetails?.newAssignment?.assignmentType === "pond"
          ? event.claimTypeDetails.newAssignment.pondName
          : "N/A"}
      </TableCell>

      {/* Lead Stage */}
      <TableCell>{event.leadStage || "N/A"}</TableCell>

      {/* Claim Date */}
      <TableCell>{claimedAtFormatted}</TableCell>

      {/* Action Button */}
      <TableCell>
        <StyledButton
          variant="contained"
          size="small"
          onClick={() => handleViewDetailsClick(event.leadId)}
        >
          View Details
        </StyledButton>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Lead Details</DialogTitle>
          <DialogContent sx={{ height: "200px", width: "500px" }}>
            <ShowStepper leadDetails={selectedLead} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export default SingleLeadClaimEntry;
