import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  Container,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  TextField,
  ListItemIcon,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { format, parseISO } from "date-fns";
import {
  TrendingUp,
  TrendingDown,
  Info,
  HelpOutline,
} from "@mui/icons-material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import DialogueBox from "../DialogueBox";
import InsightCard from "../InsightCard";
import SankeyDiagram from "../SankeyDiagram";
import PipelineChart from "../PipelineChart";
import AgentScorecard from "../AgentScorecard";
const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
}));

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const theme = createTheme({
  palette: {
    primary: { main: "#3a86ff" },
    secondary: { main: "#ff006e" },
    success: { main: "#38b000" },
    warning: { main: "#ffbe0b" },
    error: { main: "#d90429" },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h3: { fontWeight: 700, letterSpacing: "-0.5px" },
    h4: { fontWeight: 600, letterSpacing: "-0.5px" },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
    body1: { fontSize: "1rem", lineHeight: 1.7 },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
  },
}));

const LeadInsights = () => {
  const [insights, setInsights] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [dailyLogs, setDailyLogs] = useState([]);
  const [stageLogs, setStageLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const [fetchDate, setFetchDate] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [stageMap, setStageMap] = useState("");
  const [savedStages, setSavedStages] = useState([]);

  const userId = user?._id;

  const fetchSelections = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/api/get-selections`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (
        (user.subscriptionStatus === "active" &&
          user.fubApiKey &&
          !response.data) ||
        (response.data.agentIds.length === 0 &&
          response.data.smartListIds.length === 0)
      ) {
        setError("Please select metrics to review insights");
        return null;
      }

      const selections = response.data || {};
      if (selections.stageIds && selections.stageIds.length > 0) {
        setSavedStages(selections.stageIds);
      }
      return selections;
    } catch (error) {
      console.error("Error fetching selections:", error);
      setErrorMessage("Error fetching selections. Please try again.");
      return null;
    }
  }, [user]);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     await fetchSelections();
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [fetchSelections]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      try {
        const selections = await fetchSelections();
        if (
          selections &&
          selections.stageIds &&
          selections.stageIds.length > 0
        ) {
          setSavedStages(selections.stageIds);
          await handleSelection(selections);
          await fetchInsights();
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setErrorMessage("Error fetching initial data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  const handleSelection = async (selections) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_BASE_URL}/api/selected-counts`,
        {
          agentIds: selections.agentIds,
          smartListIds: selections.smartListIds,
          stageIds: selections.stageIds,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const responseData = response.data;
      if (userId) {
        localStorage.setItem(`counts-${userId}`, JSON.stringify(responseData));
      }

      if (responseData.date) {
        const parsedDate = parseISO(responseData.date);
        const formattedDate = isNaN(parsedDate)
          ? "Invalid Date"
          : format(parsedDate, "dd/MM/yyyy");
        setFetchDate(formattedDate);
      }
    } catch (error) {
      console.error("Error fetching selected counts:", error);
      setFetchDate("Invalid Date");
    }
  };

  // const saveSelections = async (stageIds) => {
  //   if (userId) {
  //     localStorage.removeItem(`stages-${userId}`);
  //   }

  //   try {
  //     const token = Cookies.get("token");
  //     const response = await axios.post(
  //       `${API_BASE_URL}/api/get-stages`,
  //       { stageIds },
  //       { headers: { Authorization: `Bearer ${token}` } }
  //     );
  //     if (response.data.success) {
  //       if (userId) {
  //         const stageIds = response.data.selections.stageIds;
  //         setSavedStages(stageIds);
  //         localStorage.setItem(`stages-${userId}`, JSON.stringify(savedStages));
  //       }

  //       return response.data.selections;
  //     }
  //   } catch (error) {
  //     console.error("Error saving selections:", error);
  //   }
  // };

  useEffect(() => {
    if (savedStages.length > 0 && userId) {
      localStorage.setItem(`stages-${userId}`, JSON.stringify(savedStages));
    }
  }, [savedStages, userId]);
  useEffect(() => {
    const savedStagesString = localStorage.getItem(`stages-${userId}`);

    if (savedStagesString) {
      try {
        const persistedSavedStages = JSON.parse(savedStagesString);
        if (Array.isArray(persistedSavedStages)) {
          setSavedStages(persistedSavedStages);
        } else {
          console.error("Expected an array but got:", persistedSavedStages);
        }
      } catch (error) {
        console.error("Error parsing saved stages:", error);
      }
    }
  }, [userId]);

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
    }
  }, [user]);

  const calculatePerformanceScore = (agent) => {
    if (!agent) return 0;
    const maxScore = 100;
    const dailyWeight = 0.6;
    const stageWeight = 0.4;

    const dailyScore = Math.max(0, 100 - (agent.avgDailyTotal || 0));
    const stageScore = Math.max(0, 100 - (agent.avgStageTotal || 0));

    const weightedScore = dailyScore * dailyWeight + stageScore * stageWeight;

    return Math.min(Math.max(weightedScore, 0), 100);
  };

  const fetchInsights = async () => {
    if (!user) {
      setError("User not authenticated");
      setLoading(true);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/lead-insights`,
        {}
      );

      setInsights(response.data.insights || null);

      const updatedAgentData = (response.data.agentData || []).map((agent) => ({
        ...agent,
        performanceScore: calculatePerformanceScore(agent),
      }));
      setAgentData(updatedAgentData);

      setDailyLogs(response.data.dailyLogs || []);

      setStageLogs(response.data.stageLogs || []);

      setStageMap(response.data.stageMap || {});
    } catch (error) {
      console.error("Error fetching lead insights:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInsights();
  }, []);

  if (user && user.subscriptionStatus === "active" && user.fubApiKey && error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        height="100vh"
        sx={{ marginTop: "280px" }}
      >
        <Typography variant="h6" gutterBottom>
          No Metrices selected. Please go to the Select Metrics to make some
          selections.
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
          sx={{ flexGrow: 1 }}
        >
          <StyledButton
            variant="contained"
            onClick={() => navigate("/")}
            fullWidth
            sx={{ height: "56px", width: "550px" }}
          >
            Go to Select Metrics
          </StyledButton>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {showFUBKEYDialog && <DialogueBox />}

      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress sx={{ color: "#02524B" }} />
        </Box>
      )}

      {savedStages.length === 0 && !loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
          height="100vh"
          sx={{ marginTop: "280px" }}
        >
          <Typography variant="h6" gutterBottom>
            No stages selected. Please go to the Select Metrics page to choose
            stages.
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={3}
            sx={{ flexGrow: 1 }}
          >
            <StyledButton
              variant="contained"
              onClick={() => navigate("/")}
              fullWidth
              sx={{ height: "56px", width: "550px" }}
            >
              Go to Select Metrics
            </StyledButton>
          </Box>
        </Box>
      )}

      <ThemeProvider theme={theme}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            position="absolute"
            top="50%"
            left="50%"
            sx={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress sx={{ color: "#02524B" }} />
          </Box>
        ) : (
          savedStages.length > 0 &&
          insights && (
            <Container maxWidth="xl">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                mt={3}
              >
                <Typography variant="h3" component="h1">
                  Agent Insights
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "40px",
                  }}
                ></Box>
              </Box>
              {savedStages.length > 0 && insights && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <InsightCard
                      title="Team Performance Overview"
                      content={insights?.teamPerformance || "No data available"}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Top Performers
                        </Typography>
                        <List>
                          {insights?.topPerformers &&
                            insights.topPerformers.map((performer, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <TrendingUp color="success" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={performer.name}
                                  secondary={performer.reason}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          Needs Improvement
                        </Typography>
                        <List>
                          {insights?.needsImprovement &&
                            insights.needsImprovement.map((agent, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <TrendingDown color="error" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={agent.name}
                                  secondary={agent.advice}
                                />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                  <>
                    <Grid item xs={12}>
                      <SankeyDiagram
                        stageLogs={stageLogs}
                        dailyLogs={dailyLogs}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PipelineChart stageLogs={stageLogs} />
                    </Grid>
                  </>

                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Agent Scorecards
                      <Tooltip title="These scorecards provide a detailed view of each agent's performance metrics and trends.">
                        <IconButton size="small">
                          <HelpOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {agentData.map((agent) => (
                      <AgentScorecard key={agent.name} agent={agent} />
                    ))}
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" gutterBottom>
                          General Tips for Improvement
                          <Tooltip title="These tips are AI-generated suggestions to help improve overall team performance.">
                            <IconButton size="small">
                              <HelpOutline fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                        <List>
                          {insights?.generalTips &&
                            insights.generalTips.map((tip, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <Info color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={tip} />
                              </ListItem>
                            ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Container>
          )
        )}
      </ThemeProvider>
    </>
  );
};

export default LeadInsights;
