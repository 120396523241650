import { React, useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import * as d3 from "d3";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";

const SankeyDiagram = ({ stageLogs, dailyLogs }) => {
  const svgRef = useRef(null);
  const [error, setError] = useState("");
  const [stageMap, setStageMap] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSelectionsAndStages = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No Token Found");
        }

        // First API Call: Fetch stageIds
        const selectionsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-selections`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const { stageIds } = selectionsResponse.data;

        if (!stageIds || stageIds.length === 0) {
          throw new Error("No stageIds found in response");
        }

        // Second API Call: Fetch all stages
        const stagesResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/stages`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Ensure type consistency: Convert stageIds to numbers
        const stageIdsAsNumbers = stageIds.map(Number);

        // Filter stages based on stageIds
        const filteredStages = stagesResponse.data.filter((stage) =>
          stageIdsAsNumbers.includes(stage.id)
        );

        // Create a map of stageId to stageName
        const stageMap = filteredStages.reduce((acc, stage) => {
          acc[stage.id] = stage.name;
          return acc;
        }, {});

        setStageMap(stageMap);
      } catch (error) {
        console.error("Error fetching selections or stage names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSelectionsAndStages();
  }, []);

  useEffect(() => {
    if (
      !stageLogs ||
      !dailyLogs ||
      stageLogs.length === 0 ||
      dailyLogs.length === 0 ||
      Object.keys(stageMap).length === 0
    ) {
      setError("No logs provided for Sankey diagram or stage map is empty");
      return;
    }

    const nodes = [];
    const links = [];
    const stageNames = new Set();

    // Add agent nodes (representing their SLZ)
    dailyLogs.forEach((agentLog) => {
      const latestLog = agentLog.logs[agentLog.logs.length - 1];
      const slzCount = latestLog?.smartListCounts["Smart List Zero"] || 0;
      nodes.push({ name: `${agentLog.agentName} SLZ`, value: slzCount });
    });

    // Collect all stage names from stageMap and fetch matching stageLogs
    Object.keys(stageMap).forEach((stageId) => {
      const stageName = stageMap[stageId];

      // Check if the stage name is already processed
      if (!stageNames.has(stageName)) {
        stageNames.add(stageName);
        nodes.push({ name: stageName });
      }

      // Fetch stageLogs matching this stageId
      stageLogs.forEach((agentLog) => {
        agentLog.logs.forEach((log) => {
          if (log && log.stageCounts && log.stageCounts[stageId]) {
            const count = log.stageCounts[stageId];

            // Ensure the stage is part of the Sankey links
            const targetIndex = nodes.findIndex((n) => n.name === stageName);
            const sourceIndex = nodes.findIndex(
              (n) => n.name === `${agentLog.agentName} SLZ`
            );

            if (sourceIndex !== -1 && targetIndex !== -1) {
              links.push({
                source: sourceIndex,
                target: targetIndex,
                value: count,
              });
            }
          }
        });
      });
    });

    if (nodes.length === 0 || links.length === 0) {
      console.error("No valid nodes or links created for Sankey diagram");
      return;
    }

    const data = { nodes, links };

    const svg = d3.select(svgRef.current);
    const { width, height } = svg.node().getBoundingClientRect();

    try {
      const sankeyGenerator = sankey()
        .nodeWidth(15)
        .nodePadding(10)
        .extent([
          [1, 1],
          [width - 1, height - 6],
        ]);

      const { nodes: sankeyNodes, links: sankeyLinks } = sankeyGenerator(data);

      svg.selectAll("*").remove();

      // Color scale for nodes
      const color = d3.scaleOrdinal(d3.schemeCategory10);

      // Nodes
      svg
        .append("g")
        .selectAll("rect")
        .data(sankeyNodes)
        .join("rect")
        .attr("x", (d) => d.x0)
        .attr("y", (d) => d.y0)
        .attr("height", (d) => d.y1 - d.y0)
        .attr("width", (d) => d.x1 - d.x0)
        .attr("fill", (d) => color(d.name.split(" ")[0])) // Color by agent name or stage
        .append("title")
        .text((d) => `${d.name}\nTotal: ${d.value}`);

      // Links
      svg
        .append("g")
        .attr("fill", "none")
        .selectAll("path")
        .data(sankeyLinks)
        .join("path")
        .attr("d", sankeyLinkHorizontal())
        .attr("stroke", (d) =>
          d3.color(color(d.source.name.split(" ")[0])).darker(0.5)
        )
        .attr("stroke-width", (d) => Math.max(1, d.width))
        .attr("opacity", 0.5)
        .append("title")
        .text((d) => `${d.source.name} → ${d.target.name}\nCount: ${d.value}`);

      // Labels
      svg
        .append("g")
        .style("font", "10px sans-serif")
        .selectAll("text")
        .data(sankeyNodes)
        .join("text")
        .attr("x", (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
        .attr("y", (d) => (d.y1 + d.y0) / 2)
        .attr("dy", "0.35em")
        .attr("text-anchor", (d) => (d.x0 < width / 2 ? "start" : "end"))
        .text((d) => d.name)
        .call((text) =>
          text
            .filter((d) => d.x0 < width / 2)
            .attr("x", (d) => d.x1 + 6)
            .attr("text-anchor", "start")
        )
        .call((text) =>
          text
            .filter((d) => d.x0 >= width / 2)
            .attr("x", (d) => d.x0 - 6)
            .attr("text-anchor", "end")
        );
    } catch (error) {
      console.error("Error generating Sankey diagram:", error);
      return;
    }
  }, [stageLogs, dailyLogs, stageMap]);

  return <svg ref={svgRef} width="100%" height="600px"></svg>;
};

export default SankeyDiagram;
