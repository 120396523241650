import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

import { HelpOutline } from "@mui/icons-material";

const PipelineChart = ({ stageLogs, columnOrder }) => {
  const [selectedAgent, setSelectedAgent] = useState("All Agents");
  const [chartData, setChartData] = useState([]);
  const [stageMap, setStageMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [stageIds, setStageIds] = useState([]);

  useEffect(() => {
    const fetchSelectionsAndStages = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No Token Found");
        }

        // First API Call: Fetch stageIds
        const selectionsResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/get-selections`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const { stageIds } = selectionsResponse.data;

        if (!stageIds || stageIds.length === 0) {
          throw new Error("No stageIds found in response");
        }

        // Set the fetched stageIds
        setStageIds(stageIds);

        // Second API Call: Fetch all stages
        const stagesResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/stages`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Ensure type consistency: Convert stageIds to numbers
        const stageIdsAsNumbers = stageIds.map(Number);

        // Filter stages based on stageIds
        const filteredStages = stagesResponse.data.filter((stage) =>
          stageIdsAsNumbers.includes(stage.id)
        );

        // Create a map of stageId to stageName
        const map = filteredStages.reduce((acc, stage) => {
          acc[stage.id] = stage.name;
          return acc;
        }, {});

        setStageMap(map);
      } catch (error) {
        console.error("Error fetching selections or stage names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSelectionsAndStages();
  }, []);

  useEffect(() => {
    updateChartData();
  }, [selectedAgent, stageLogs, columnOrder, stageMap, stageIds]);

  const updateChartData = () => {
    if (stageLogs.length === 0 || Object.keys(stageMap).length === 0) {
      setChartData([]);
      return;
    }

    let dataToUse = stageLogs;

    if (selectedAgent !== "All Agents") {
      dataToUse = stageLogs.filter((log) => log.agentName === selectedAgent);
    }

    const latestLogs = dataToUse.reduce((acc, agentLog) => {
      const latestLog = agentLog.logs.reduce((latest, log) => {
        return !latest || new Date(log.date) > new Date(latest.date)
          ? log
          : latest;
      }, null);

      if (latestLog) {
        acc[agentLog.agentName] = latestLog;
      }
      return acc;
    }, {});

    const stageData = Object.values(latestLogs).reduce((acc, log) => {
      if (log.stageCounts) {
        Object.entries(log.stageCounts).forEach(([stageId, count]) => {
          const stageIdNumber = Number(stageId); // Ensure the stageId is treated as a number

          // Ensure that the stageIdNumber is included in the stageIds array
          if (stageIds.map(Number).includes(stageIdNumber)) {
            const stageName =
              stageMap[stageIdNumber] || `Unknown Stage (ID: ${stageIdNumber})`;
            if (!acc[stageName]) acc[stageName] = 0;
            acc[stageName] += Number(count);
          }
        });
      } else {
        console.error("Missing stageCounts for log:", log);
      }
      return acc;
    }, {});

    // Ensure that all selected stages are included in the ordered stage data, even if they have no count
    const order = columnOrder?.order || Object.keys(stageData);
    const labels = columnOrder?.labels || {};

    const orderedStageData = stageIds.map((stageId) => {
      const stageName =
        labels[stageId] ||
        stageMap[stageId] ||
        `Unknown Stage (ID: ${stageId})`;
      const count = stageData[stageName] || 0; // Ensure that the stage is always included with count 0 if not found
      return {
        stageName,
        count,
      };
    });

    // Ensure that orderedStageData contains the correct data and update state
    setChartData(orderedStageData);
  };

  const agents = [
    "All Agents",
    ...new Set(stageLogs.map((log) => log.agentName)),
  ];

  return (
    chartData?.length > 0 && (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Sales Pipeline
            <Tooltip title="This chart shows the number of leads in each stage of the sales pipeline.">
              <IconButton size="small">
                <HelpOutline fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Agent</InputLabel>
            <Select
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
              label="Select Agent"
            >
              {agents.map((agent) => (
                <MenuItem key={agent} value={agent}>
                  {agent}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData}>
                <XAxis dataKey="stageName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Lead Count" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography>
              No pipeline data available for the selected agent.
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  );
};

export default PipelineChart;
